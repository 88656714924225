//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { areaInfo } from "~/static/js/areaInfo";
import Tabs from "@/components/Design/Tabs.vue";
import { mapActions, mapMutations } from "vuex";
import { loadJsAsync } from "~~/utils";
export default {
  props: {
    // 父辈向子辈传参
  },
  name: "login",
  layout: "normal",
  comments: {},
  data() {
    return {
      tabsItem: [
        { title: "email", index: 0 },
        { title: "phone number", index: 1 },
      ],
      email: "",
      password: "",
      phone: "",
      code: 1,
      identify_code: "",
      isCurrent: true,
      isCurrent_phone: false,
      areaInfoArr: [],
      isSend: false,
      num: 60,
      showPassWord: false,
    };
  },
  asyncData() {
    // let area_Code= await this.$()
  },

  created() {
    // 实例被创建之后执行代码
  },
  computed: {
    // 计算属性
  },
  components: {
    // 组件的引用
    Tabs,
  },
  methods: {
    ...mapActions("user", ["thridLoginAction", "initThridLogin", "getUserInfoAll"]),
    ...mapMutations("user", ["setIsLogin", "setUserInfo"]),
    ...mapActions("cart", ["getCartList"]),
    //第三方登陆
    thridLogin(type) {
      console.log("点击了吗");
      //派发action
      this.thridLoginAction({ type });
    },
    //初始化
    async initScirpt() {
      console.log("挂载sdk");
      await loadJsAsync("https://connect.facebook.net/en_US/sdk.js", "", { id: "facebookScriptId", crossorigin: "anonymous" });
      await loadJsAsync("https://accounts.google.com/gsi/client", "", { id: "googleClientScriptId" });
      await loadJsAsync("https://apis.google.com/js/api.js", "", { id: "googleApiScriptId" });
      setTimeout(() => {
        this.initThridLogin();
      }, 100);
    },
    // 登陆
    async loginSubmit() {
      if (this.isCurrent) {
        //邮箱登陆
        const success = await this.$validator.validateAll(["email", "password"]);
        if (success) {
          let params = {
            email: this.email,
            phone: this.phone,
            password: this.password,
          };
          let res = await this.$submitLogin(params, "/api/auth/login");
          console.log(res);
          if (res.code == 200000) {
            try {
              this.$cookies.set("userInfo", res.data, {
                maxAge: 60 * 60 * 24 * 120,
                path: "/",
              });
              this.setIsLogin(true);
              this.setUserInfo(res.data.user);
              this.getCartList();
              this.$router.push({
                path: "/user",
              });
              this.googleCustomeEventHanlder("login_success", "click", "login_success", "Register/Login");
            } catch (error) {
              console.log("邮箱登陆报错" + error);
            }
          }
        }
      } else {
        // 电话登陆
        console.log("电话登陆");
        let result = await this.$validator.validateAll(["phone", "identify_code", "code"]);
        if (result) {
          let params = {
            phone: this.phone,
            code: this.code,
            identify_code: this.identify_code,
          };
          try {
            let res = await this.$submitLogin(params, "/api/auth/login/phone");
            if (res.code == 200000) {
              this.$cookies.set("userInfo", res.data.token, {
                maxAge: 60 * 60 * 24 * 120,
                path: "/",
              });
              this.setIsLogin(true);
              this.setUserInfo(res.data);
              this.getCartList();
              this.$router.push({
                path: "/user",
              });

              this.googleCustomeEventHanlder("login_success", "click", "login_success", "Register/Login");
            }
          } catch (error) {
            console.log("电话登陆报错" + error);
          }
        }
        console.log(result);
      }
    },
    handler(index) {
      console.log(index);
      if (this.isCurrent) {
        this.isCurrent = false;
        this.isCurrent_phone = true;
      } else {
        this.isCurrent = true;
        this.isCurrent_phone = false;
      }
    },
    /**
     * @description:  获取验证码
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-16 09:54:26
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    async getCode() {
      if (this.isSend) return;
      let result = await this.$validator.validateAll(["phone", "code"]);
      console.log(result);
      if (result) {
        this.isSend = true;
        this.countDown();
        let params = {
          phone: this.phone,
          code: this.code,
          identify_code: this.identify_code,
        };

        let res = await this.$nuxt.$getCode(params);
        console.log(res);
      }
    },

    /**
     * @description: 倒计时
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-16 09:51:16
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    countDown() {
      let _coutdown = setInterval(() => {
        if (this.num == 0) {
          this.isSend = false;
          this.num = 60;
          clearInterval(_coutdown);
        } else {
          --this.num;
        }
      }, 1000);
    },
    /**
     * @description: 小眼睛功能
     * @return {*}
     * @Author: liudanli
     * @Date: 2023-03-21 16:58:46
     * @LastTime: Do not edit
     * @LastAuthor: liudanli丶
     * @Description: Do not Description
     */
    showPassWordFun() {
      if (!this.showPassWord) {
        this.showPassWord = true;
      } else {
        this.showPassWord = false;
      }
    },
  },
  mounted() {
    // 页面进入时加载内容
    this.areaInfoArr = areaInfo;
    // 挂载第三方登陆sdk
    this.initScirpt();
  },
  watch: {
    // 监测变化
  },
};
